<template>
  <div>
    <div class='card card-top card-top-primary'>
      <div class='card-header d-flex justify-content-between align-items-center'>
        <vb-headers-card-header :data="{ title: 'Ders Paketi Ekleme' }" />
        <a-button class='btn btn-with-addon btn-success' :loading='loading' :disabled='!validateForm' @click='addPackageCourse'>
          <span class='btn-addon'>
            <i class='btn-addon-icon fe fe-plus'></i>
          </span>
          Ders Paketi Ekle
        </a-button>
      </div>

      <div class='card-body'>
        <div class='row'>
          <div class='col-12 col-lg-6'>

            <image-resizer
              :loading='loading'
              :on-complete='uploadPhoto'
              :max-size='972'
              make-square
              required
              :error-text='"Fotoğraf en az 1000x1000 piksel ölçülerinde kare olmalıdır."'
            />

            <a-form label-align='left' layout='vertical'>
              <a-form-item label='Ders Paketi Adı (*)' name='mainTitle'>
                <a-input v-model:value='name' />
              </a-form-item>

              <a-form-item label='Fiyatı (*)' name='subTitle'>
                <a-input addon-after='₺' v-model:value='price' v-maska='"######"' />

                <a-tag color='success' v-if='price === "0"' class='mt-2'>
                  Ücretsiz
                </a-tag>
              </a-form-item>

              <a-form-item label='Sıralaması' name='email'>
                <a-input v-maska.raw="'########'" v-model:value='pcOrder' placeholder='Sıralaması' />
              </a-form-item>
            </a-form>
          </div>
          <div class='col-12 col-lg-6'>
            <a-form label-align='left' layout='vertical'>
              <a-form-item label='Açıklama Yazısı (*)' name='packageDescription'>
                <a-textarea v-model:value='description' />
              </a-form-item>

              <a-form-item label='Video Linki (*)' name='packageVideo'>
                <a-input v-model:value='vimeoPromotionalVideoUrl' />
              </a-form-item>

              <a-form-item label='Antrenör (*)' name='broadcastPt'>
                <a-select
                  v-model:value='value'
                  placeholder='Antrenör Seçin'
                  :show-search='true'
                  :show-arrow='true'
                  :filter-option='false'
                  :on-popup-scroll='onScrollTrainersDropdown'
                  :not-found-content='fetching ? undefined : null'
                  :options='data'
                  :loading='fetching'
                  @search='loadTrainers'
                  @change='handlePersonalTrainerChange'
                >
                  <template v-if='fetching' #notFoundContent>
                    <a-spin size='small' />
                  </template>
                </a-select>
              </a-form-item>
              <div v-if='value !== ""' style='width:100%;height:150px; display:flex; justify-content:center;'>
                <a-image
                  style='width: 150px;border-radius:200px; border:1px solid #7d7d7d '
                  :src='trainers.find(x => x.metadataId === value).avatar.avatar4X ? trainers.find(x => x.metadataId === value).avatar.avatar4X.replace("small_user_avatar", "user_avatar") : " "'
                  alt='User'
                  fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                />
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PackageCourseAdministration, PersonalTrainer } from '../../services/api-service'
import { useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import { reactive, toRefs } from 'vue'
import { debounce } from 'lodash'

export default {
  name: 'PaketDersEkleme',
  data() {
    const router = useRouter()

    const uploadPhoto = (file) => {
      this.coverPhotoBase64 = file
    }

    const state = reactive({
      data: [],
      value: '',
      trainers: [],
      searchValue: '',
      pagination: { isNextPageAvailable: false, firstIdOfNextPage: 0 },
      fetching: false,
    })

    const loadTrainers = debounce(async value => {
      state.data = []
      state.fetching = true
      state.searchValue = value

      try {
        const { data } = await PersonalTrainer.listPersonalTrainers(undefined, value)
        if (data) {
          state.trainers = data.personalTrainers
          state.data = data.personalTrainers.map((pt) => ({
            value: pt.metadataId,
            label: pt.fullName,
          }))

          state.pagination = data.pagination
        }
      } catch {
      }

      state.fetching = false
    }, 300)

    const loadTrainersNextPage = debounce(async () => {
      if (state.pagination.isNextPageAvailable) {
        state.fetching = true
        try {
          const { data } = await PersonalTrainer.listPersonalTrainers(state.pagination.firstIdOfNextPage, state.searchValue)
          if (data) {
            state.data = [...state.data, ...data.personalTrainers.map((pt) => ({
              value: pt.metadataId,
              label: pt.fullName,
            }))]

            state.pagination = data.pagination
          }
        } catch {
          state.pagination = { isNextPageAvailable: false, firstIdOfNextPage: 0 }
        }
        state.fetching = false
      }
    }, 300)

    return {
      router,
      uploadPhoto,
      loadTrainers,
      loadTrainersNextPage,
      trainers: [],
      loading: false,
      name: '',
      description: '',
      coverPhotoBase64: '',
      personalTrainerId: '',
      vimeoPromotionalVideoUrl: '',
      price: '0',
      pcOrder: '0',

      ...toRefs(state),
    }
  },
  computed: {
    validateForm() {
      return this.name !== '' && this.description !== '' && this.coverPhotoBase64 !== '' && this.personalTrainerId !== '' && this.price !== '' && this.vimeoPromotionalVideoUrl !== ''
    },
  },
  mounted() {
    this.loadTrainers('')
  },
  methods: {
    async addPackageCourse() {
      this.loading = true

      try {
        const { data } = await PackageCourseAdministration.createPackageCourse({
          name: this.name,
          description: this.description,
          coverPhotoBase64: this.coverPhotoBase64,
          personalTrainerId: parseInt(this.personalTrainerId),
          price: parseInt(this.price),
          vimeoPromotionalVideoUrl: this.vimeoPromotionalVideoUrl,
          trainings: [],
          pcOrder: parseInt(this.pcOrder),
        })

        notification.success({
          message: 'Ders Paketi Başarıyla Oluşturuldu!',
          description: 'Antrenman ve Set Eklemeyi Unutmayınız!',
        })

        await this.router.push({ path: `/ders-paket-detaylari/${data.id}` })
      } catch (e) {
        console.log({ e })
      }
      this.loading = false
    },
    async handlePersonalTrainerChange(val) {
      this.personalTrainerId = val
    },
    async onScrollTrainersDropdown(event) {
      const target = event.target
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.loadTrainersNextPage()
      }
    },
  },
}
</script>
